import React, { useEffect, useState } from 'react';
import InputField from '../../components/input';
import SelectField from '../../components/select';
import { useNavigate } from 'react-router-dom';
import { LoginCredentials, loginUser, logout, UpdateLoginUserData } from '../../auth/userSlice';
import './index.css'
import { useAddDispatch, useAppSelector } from '../../store';
import { compCode } from '../../data/dropDownData';



function Login(props: any) {

    const navigate = useNavigate();
    const dispatch = useAddDispatch();

    const [credentials, setCredentials] = useState<LoginCredentials>({ compCode: 'GW', emailOrMobileNo: '', password: '' });
    const { isLoading } = useAppSelector((state: any) => state.userSlice);
    const [errormsg, setErrormsg] = useState("");
    const [errors, setErrors] = useState<{ compCode?: string, emailOrMobileNo?: string, password?: string }>({});


    const validate = (): boolean => {
        let valid = true;
        let errors: { compCode?: string, emailOrMobileNo?: string, password?: string } = {};

        // if (!credentials.compCode) {
        //     valid = false;
        //     errors.compCode = 'Company Code is required';
        // }

        if (!credentials.emailOrMobileNo) {
            valid = false;
            errors.emailOrMobileNo = 'Username is required';
        }

        if (!credentials.password) {
            valid = false;
            errors.password = 'Password is required';
        }

        setErrors(errors);
        return valid;
    };
    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setCredentials({ ...credentials, [name]: value });
        validate();
        setErrormsg("");
    };
    const handleSelectChange = (e: any) => {
        const value = e.value;
        setCredentials({ ...credentials, compCode: value });
        validate();
        setErrormsg("");
    };
    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setErrormsg("");
        if (validate()) {
            const response = await dispatch(loginUser(credentials));

            if (response.payload?.isSuccess) {
                sessionStorage.setItem("user", JSON.stringify(response.payload.result));
                //let access_token = response?.payload?.result?.token;
                navigate('/dashboard');
            } else {
                setErrormsg("Username or password is incorrect");
                console.log(response.payload?.resultMessage);
                return false;
            }
        }
    }
    useEffect(() => {
        dispatch(logout())
        sessionStorage.removeItem("user");

    }, [dispatch]);

    return (
        <>
            <div className="container-fluid page-body-wrapper full-page-wrapper transparent-bg">
                <div className="login-content-wrapper content-wrapper d-flex align-items-center auth px-0">
                    <div className="row w-100 mx-0">
                        <div className="col-lg-4 mx-auto">
                            <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                                <div className="brand-logo">
                                    <img src="assets/images/logo.png" alt="logo"></img>
                                </div>
                                <h6 className="font-weight-light">Sign in to continue.</h6>
                                <form method="post" onSubmit={handleSubmit} className="pt-3">
                                    {/* <div className="form-group" >
                                        <SelectField className="form-control-lg bg-white" name="compCode"
                                            placeholder="Company code.."
                                            data={compCode} isLoading={false} isClearable={false}
                                            value={credentials.compCode} selectedValue={credentials.compCode} onChange={handleSelectChange} />
                                        {errors.compCode && <div style={{ color: 'red' }}>{errors.compCode}</div>}
                                    </div> */}
                                    <div className="form-group">
                                        <InputField
                                            selectedValue={credentials.emailOrMobileNo} onChange={handleChange}
                                            className="form-control form-control-lg bg-white" name="emailOrMobileNo"
                                            placeholder="Username" />
                                        {errors.emailOrMobileNo && <div style={{ color: 'red' }}>{errors.emailOrMobileNo}</div>}
                                    </div>
                                    <div className="form-group">
                                        <InputField selectedValue={credentials.password} onChange={handleChange}
                                            type="password" className="form-control form-control-lg bg-white" name="password"
                                            placeholder="Password" />
                                        {errors.password && <div style={{ color: 'red' }}>{errors.password}</div>}
                                        {/* <span data-toggle="#password" className="fa fa-eye field_icon data-toggle-password"></span> */}
                                    </div>
                                    <div className="mt-3 d-grid gap-2">
                                        <button disabled={isLoading}
                                            className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn">
                                            {isLoading ? 'SIGN IN...' : 'SIGN IN'}</button>
                                        {errormsg && <p style={{ color: 'red' }}>{errormsg}</p>}
                                    </div>
                                    {/* <div className="my-2 d-flex justify-content-between align-items-center">
                                        <div className="form-check">
                                            <label className="form-check-label text-muted">
                                                <InputField type="checkbox" className="form-check-input" />
                                                <i className="input-helper"></i>
                                                Keep me signed in
                                            </label>
                                        </div>
                                        <a href="#" className="auth-link text-black">Forgot password?</a>
                                    </div> */}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {/* content-wrapper ends */}
            </div>
            {/* page-body-wrapper ends  */}
        </>
    )
}
export default Login;
