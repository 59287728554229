import { useEffect, useState } from "react";
import { useAppSelector } from "../../store";

const Sidebar = (props: any) => {
    const userSlice = useAppSelector((state: any) => state.userSlice);
    const [mDashboard, setmDashboard] = useState({ dashboard: false });
    const [mUserManager, setmUserManager] = useState({ userManager: false, users: false, modulePermission: false, roleMapping: false });
    const [mPackageMaster, setmPackageMaster] = useState({ packageMaster: false, region: false, country: false, city: false, tourCategory: false, discountMaster: false });
    const [mFixedPackage, setmFixedPackage] = useState({ fixedPackage: false, addPackage: false, specialPackage: false });
    const [mModifyPackage, setmModifyPackage] = useState({ modifyPackage: false, departureCity: false, departureDate: false, cost: false, itinerary: false });

    let handleNavFunc = props.handleNavFunc;
    const [collapse, setcollapse] = useState("");
    const toggleFunc = (val: any) => {
        const prevValue = collapse;
        if (prevValue === val)
            setcollapse("");
        else
            setcollapse(val);

    };
    useEffect(() => {
        let modulePermission = userSlice?.user?.modulePermission;

        let _dashboard = modulePermission?.filter((f: any) => f.moduleCode.toLowerCase() == "dashboard" && f.view).length > 0;
        setmDashboard({ ...mDashboard, dashboard: _dashboard });

        let _userManager = modulePermission?.filter((f: any) => (f.moduleCode.toLowerCase() == "users" && f.view) || (f.moduleCode.toLowerCase() == "modulepermission" && f.view) || (f.moduleCode.toLowerCase() == "rolemapping" && f.view)).length > 0;
        let _users = modulePermission?.filter((f: any) => f.moduleCode.toLowerCase() == "users" && f.view).length > 0;
        let _modulePermission = modulePermission?.filter((f: any) => f.moduleCode.toLowerCase() == "modulepermission" && f.view).length > 0;
        let _roleMapping = modulePermission?.filter((f: any) => f.moduleCode.toLowerCase() == "rolemapping" && f.view).length > 0;
        setmUserManager({ ...mUserManager, userManager: _userManager, users: _users, modulePermission: _modulePermission, roleMapping: _roleMapping });

        let _packageMaster = modulePermission?.filter((f: any) => (f.moduleCode.toLowerCase() == "region" && f.view) || (f.moduleCode.toLowerCase() == "country" && f.view) || (f.moduleCode.toLowerCase() == "city" && f.view) || (f.moduleCode.toLowerCase() == "tourcategory" && f.view) || (f.moduleCode.toLowerCase() == "discountmaster" && f.view)).length > 0;
        let _region = modulePermission?.filter((f: any) => f.moduleCode.toLowerCase() == "region" && f.view).length > 0;
        let _country = modulePermission?.filter((f: any) => f.moduleCode.toLowerCase() == "country" && f.view).length > 0;
        let _city = modulePermission?.filter((f: any) => f.moduleCode.toLowerCase() == "city" && f.view).length > 0;
        let _tourCategory = modulePermission?.filter((f: any) => f.moduleCode.toLowerCase() == "tourcategory" && f.view).length > 0;
        let _discountMaster = modulePermission?.filter((f: any) => f.moduleCode.toLowerCase() == "discountmaster" && f.view).length > 0;
        setmPackageMaster({ ...mPackageMaster, packageMaster: _packageMaster, region: _region, country: _country, city: _city, tourCategory: _tourCategory, discountMaster: _discountMaster });

        let _fixedPackage = modulePermission?.filter((f: any) => (f.moduleCode.toLowerCase() == "specialpackage" && f.view) || (f.moduleCode.toLowerCase() == "addpackage" && f.view)).length > 0;
        let _specialPackage = modulePermission?.filter((f: any) => f.moduleCode.toLowerCase() == "specialpackage" && f.view).length > 0;
        let _addPackage = modulePermission?.filter((f: any) => f.moduleCode.toLowerCase() == "addpackage" && f.view).length > 0;
        setmFixedPackage({ ...mFixedPackage, fixedPackage: _fixedPackage, specialPackage: _specialPackage, addPackage: _addPackage });

        let _modifyPackage = modulePermission?.filter((f: any) => (f.moduleCode.toLowerCase() == "departurecity" && f.view) || (f.moduleCode.toLowerCase() == "departuredate" && f.view) || (f.moduleCode.toLowerCase() == "cost" && f.view) || (f.moduleCode.toLowerCase() == "itinerary" && f.view)).length > 0;
        let _departureCity = modulePermission?.filter((f: any) => f.moduleCode.toLowerCase() == "departurecity" && f.view).length > 0;
        let _departureDate = modulePermission?.filter((f: any) => f.moduleCode.toLowerCase() == "departuredate" && f.view).length > 0;
        let _cost = modulePermission?.filter((f: any) => f.moduleCode.toLowerCase() == "cost" && f.view).length > 0;
        let _itinerary = modulePermission?.filter((f: any) => f.moduleCode.toLowerCase() == "itinerary" && f.view).length > 0;
        setmModifyPackage({ ...mModifyPackage, modifyPackage: _modifyPackage, departureCity: _departureCity, departureDate: _departureDate, cost: _cost, itinerary: _itinerary });

    }, [userSlice]);
    return (
        <>
            <nav className="sidebar sidebar-offcanvas" id="sidebar">
                <ul className="nav">
                    {mDashboard.dashboard &&
                        <li className="nav-item">
                            <a className="nav-link" onClick={() => handleNavFunc("/dashboard")}>
                                <i className="icon-grid menu-icon"></i>
                                <span className="menu-title">Dashboard</span>
                            </a>
                        </li>
                    }
                    {mUserManager.userManager &&
                        <li className="nav-item">
                            <a className={collapse == "UserManager" ? "nav-link" : "nav-link"} data-bs-toggle={collapse == "UserManager" ? "collapse" : "collapse"}
                                onClick={() => toggleFunc("UserManager")} aria-expanded={collapse == "UserManager" ? "true" : "false"} >
                                <i className="icon-layout menu-icon"></i>
                                <span className="menu-title">
                                    User Manager
                                </span>
                                <i className="menu-arrow"></i>
                            </a>
                            <div className={collapse == "UserManager" ? "collapse show" : "collapse"} id="ui-basic">
                                <ul className="nav flex-column sub-menu">
                                    {mUserManager.users && <li className="nav-item"> <a className="nav-link" onClick={() => handleNavFunc("/users")}>Users</a></li>}
                                    {mUserManager.modulePermission && <li className="nav-item"> <a className="nav-link" onClick={() => handleNavFunc("/modulepermission")}>Module Permission</a></li>}
                                    {mUserManager.roleMapping && <li className="nav-item"> <a className="nav-link" onClick={() => handleNavFunc("/rolemapping")}>Role Mapping</a></li>}
                                </ul>
                            </div>
                        </li>
                    }
                    {mPackageMaster.packageMaster &&
                        <li className="nav-item">
                            <a className={collapse == "PackageMaster" ? "nav-link" : "nav-link"} data-bs-toggle={collapse == "PackageMaster" ? "collapse" : "collapse"}
                                onClick={() => toggleFunc("PackageMaster")} aria-expanded={collapse == "PackageMaster" ? "true" : "false"} >
                                <i className="icon-image menu-icon"></i>
                                <span className="menu-title">Package Master</span>
                                <i className="menu-arrow"></i>
                            </a>
                            <div className={collapse == "PackageMaster" ? "collapse show" : "collapse"} id="ui-advanced">
                                <ul className="nav flex-column sub-menu">
                                    {mPackageMaster.region && <li className="nav-item"> <a className="nav-link" onClick={() => handleNavFunc("/region")}>Region</a></li>}
                                    {mPackageMaster.country && <li className="nav-item"> <a className="nav-link" onClick={() => handleNavFunc("/country")}>Country</a></li>}
                                    {mPackageMaster.city && <li className="nav-item"> <a className="nav-link" onClick={() => handleNavFunc("/city")}>City</a></li>}
                                    {mPackageMaster.tourCategory && <li className="nav-item"> <a className="nav-link" onClick={() => handleNavFunc("/tourCategory")}>Tour Category</a></li>}
                                    {mPackageMaster.discountMaster && <li className="nav-item"> <a className="nav-link" onClick={() => handleNavFunc("/discountMaster")}>Discount Master</a></li>}
                                </ul>
                            </div>
                        </li>
                    }
                    {mFixedPackage.fixedPackage &&
                        <li className="nav-item">
                            <a className={collapse == "FixedPackage" ? "nav-link" : "nav-link"} data-bs-toggle={collapse == "FixedPackage" ? "collapse" : "collapse"}
                                onClick={() => toggleFunc("FixedPackage")} aria-expanded={collapse == "FixedPackage" ? "true" : "false"} >
                                <i className="icon-columns menu-icon"></i>
                                <span className="menu-title">
                                    Fixed Package</span>
                                <i className="menu-arrow"></i>
                            </a>
                            <div className={collapse == "FixedPackage" ? "collapse show" : "collapse"} id="form-elements">
                                <ul className="nav flex-column sub-menu">
                                    {mFixedPackage.specialPackage && <li className="nav-item"><a className="nav-link" href="#">Speciality Tours</a></li>}
                                    {mFixedPackage.addPackage && <li className="nav-item"><a className="nav-link" href="">Add Package</a></li>}
                                </ul>
                            </div>
                        </li>
                    }
                    {mModifyPackage.modifyPackage &&
                        <li className="nav-item">
                            <a className={collapse == "ModifyPackage" ? "nav-link" : "nav-link"} data-bs-toggle={collapse == "ModifyPackage" ? "collapse" : "collapse"}
                                onClick={() => toggleFunc("ModifyPackage")} aria-expanded={collapse == "ModifyPackage" ? "true" : "false"} >
                                <i className="icon-columns menu-icon"></i>
                                <span className="menu-title">
                                    Modify Package</span>
                                <i className="menu-arrow"></i>
                            </a>
                            <div className={collapse == "ModifyPackage" ? "collapse show" : "collapse"} id="fixed">
                                <ul className="nav flex-column sub-menu">
                                    {mModifyPackage.departureCity && <li className="nav-item"><a className="nav-link" href="#">Departure City</a></li>}
                                    {mModifyPackage.departureDate && <li className="nav-item"><a className="nav-link" href="#">Departure Date</a></li>}
                                    {mModifyPackage.cost && <li className="nav-item"><a className="nav-link" href="#">Cost</a></li>}
                                    {mModifyPackage.itinerary && <li className="nav-item"><a className="nav-link" href="#">Itinerary</a></li>}
                                </ul>
                            </div>
                        </li>
                    }
                </ul>
            </nav>
        </>
    )
};
export default Sidebar

