import React from 'react';
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import Login from '../features/login';
import AuthComponent from './authcomponent';
import PageNotFound from '../features/page-not-found/pagenotfound';

export function withAuth() {
    return (Component: any) => (prop: any) => {
        return (
            <>
                <Routes>
                    {/* <Route path='/login' element={<Login />} /> */}
                    <Route path='/' element={<Login />} />
                    {/* <Route path='*' element={<PageNotFound />} /> */}
                </Routes>
                <AuthComponent {...{ Component, prop }} />
            </>
        )
    }
};
export default withAuth;

