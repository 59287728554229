import { useNavigate } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Router from "../../routes/router";
import { useAddDispatch, useAppSelector } from "../../store";
import { isLoggedIn, User } from "../../auth/userManager";
import { useEffect, useState } from "react";
import { UpdateLoginUserData } from "../../auth/userSlice";

function MainLayout(props: any) {
    const dispatch = useAddDispatch();
    //const IsLoggedIn = isLoggedIn();    
    //const user = useAppSelector((state: any) => state.userSlice);
    const IsLoggedIn = isLoggedIn();
    console.log('MainLayout - ' + IsLoggedIn);
    const navigate = useNavigate();
    const handleNavFunc = async (redirectTo: any) => {
        navigate(redirectTo);
    }    
    useEffect(() => {
        dispatch(UpdateLoginUserData(User()));         
    }, [dispatch]);

    return (
        <>
            {IsLoggedIn && <Header handleNavFunc={handleNavFunc} />}
            <div className="container-fluid page-body-wrapper">
                {IsLoggedIn && <Sidebar handleNavFunc={handleNavFunc} />}
                <Router></Router>
            </div>
            {IsLoggedIn && <Footer />}
        </>
    );
};

export default MainLayout