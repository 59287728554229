import React from "react";
const InputField = ({
    type = "text",
    id,
    name,
    className,
    selectedValue,
    onChange,
    maxLength,
    readOnly,
    disabled,
    placeholder,
    checked
}: any
) => {
    return (
        <input
            type={type}
            id={id ? id : name}
            name={name}
            className={className}
            value={selectedValue}
            onChange={onChange}
            maxLength={maxLength}
            readOnly={readOnly}
            disabled={disabled}
            placeholder={placeholder}
            checked={checked}
        />
    )
};
export default InputField;