const userManagerApiUrl = {
    LoginUser: 'api/Users/LoginUser',
    GetAllUsers: 'api/Users/GetAllUsers',
    GetUserById: 'api/Users/GetUserById',
    AddUpdateUser: 'api/Users/AddUpdateUser',
    DeleteUserById: 'api/Users/DeleteUserById',
    
    GetListValue: "api/Common/GetListValue",

    GetModuleRoleMappings: "api/ModuleRoleMapping/GetModuleRoleMappings",
    AddModuleRoleMappings: "api/ModuleRoleMapping/AddModuleRoleMappings",
    UpdateModuleRoleMappings: "api/ModuleRoleMapping/UpdateModuleRoleMappings"
}
export default userManagerApiUrl;