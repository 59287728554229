import axios from "axios";
import { useAppSelector } from "../store";
const user = sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user") || "") : null;
let userToken = user?.token;
//let userToken = user?.user?.token;
export const http = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        Authorization: `Bearer ${userToken}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true
    }
});

export const GET = (action: any) => {
    return http.get(action);
};
export const POST = (action: any, data: any) => {
    return http.post(action, data);
};
export const PATCH = (action: any, data: any) => {
    return http.patch(action, data);
};
export const PUT = (action: any, data: any) => {
    return http.put(action, data);
};
export const DELETE = (action: any) => {
    return http.delete(action);
}
