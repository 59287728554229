// import { useAppSelector } from "../store";

// const user = useAppSelector((state) => state.userSlice);
export const User = () => {
     const user = sessionStorage.getItem("user");
     return user ? JSON.parse(user) : null;
    //return user.isLoggedIn;
}
export const isLoggedIn = () => {
    const user = User();
    if (!user) {
        return false;
    }
    return true;
}