import { lazy, Suspense } from "react";
import Loader from "../components/loaders/loader";
import { Route, Routes } from "react-router-dom";

const Dashboard = lazy(() => import("../features/dashboard/dashboard"));
const PageNotFound = lazy(() => import("../features/page-not-found/pagenotfound"));
const Login = lazy(() => import("../features/login"));
const User = lazy(() => import("../features/userManager/users/addUser"));
const ModuleRolePermission = lazy(() => import("../features/userManager/moduleRolePermission"));
const RoleMapping = lazy(() => import("../features/userManager/userRoleMapping"));
const Region = lazy(() => import("../features/packageManager/region"));
const Country = lazy(() => import("../features/packageManager/country"));
const Unauthorized = lazy(() => import("../features/unauthorized/unauthorized"));
const City = lazy(() => import("../features/packageManager/city"));
const TourCategory = lazy(() => import("../features/packageManager/tourCategory"));
const DiscountMaster = lazy(() => import("../features/packageManager/discountMaster"));

export default function Router({ value }: any) {
    return (
        <>
            <Suspense fallback={<div><Loader /></div>}>
                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path='/dashboard' element={<Dashboard />} />
                    <Route path='/users' element={<User />} />
                    <Route path='/modulepermission' element={<ModuleRolePermission />} />
                    <Route path='/rolemapping' element={<RoleMapping />} />
                    <Route path='/region' element={<Region />} />
                    <Route path='/country' element={<Country />} />
                    <Route path='/city' element={<City />} />
                    <Route path='/tourCategory' element={<TourCategory />} />
                    <Route path='/discountMaster' element={<DiscountMaster />} />
                    <Route path='*' element={<PageNotFound />} />
                    <Route path='/unauthorized' element={<Unauthorized />} />
                    
                </Routes>
            </Suspense>
        </>
    )
}