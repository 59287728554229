import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { POST } from "../config/HttpCommon";
import userManagerApiUrl from "../features/userManager/userManagerAPIURL";
export interface LoginCredentials {
    compCode: string;
    emailOrMobileNo: string;
    password: string;
}
export const loginUser = createAsyncThunk('auth/loginUser', async (credentials: LoginCredentials, thunkAPI: any) => {
    try {
        const response = await POST(userManagerApiUrl.LoginUser, credentials);
        return response.data;
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error?.response?.data);
    }
});

export const userSlice = createSlice({
    name: "userSlice",
    initialState: {
        user: null,
        isLoading: false,
        isLoggedIn: false,
        error: null,
    },
    reducers: {
        logout: (state) => {
            state.user = null;
            state.isLoggedIn = false;
        },
        UpdateLoginUserData: (intialState: any, action: any) => {
            return {
                ...intialState,
                user: action?.payload,
                isLoggedIn : true
            };
        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(loginUser.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                state.isLoading = false;
                if (action.payload.isSuccess) {
                    state.user = action.payload.result;
                    state.isLoggedIn = true;
                }
                else {
                    state.user = null;
                    state.isLoggedIn = false;
                    state.error = action.payload.resultMessage;
                }
            })
            .addCase(loginUser.rejected, (state:any, action:any) => {
                state.isLoading = false;
                state.error = action.payload?.resultMessage;
            });
    },
});

export const { UpdateLoginUserData, logout } = userSlice.actions;
export default userSlice.reducer;