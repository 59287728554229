
import './App.css';
import MainLayout from './features/layouts/MainLayout';
import withAuth from './auth/authwrapper';
import 'react-toastify/dist/ReactToastify.css';
import { Bounce, ToastContainer } from 'react-toastify';


function App() {
  return (
    <>
      <div className="container-scroller">
        <MainLayout />
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Bounce}
      />
    </>
  );
}

export default withAuth()(App);
//export default App;
