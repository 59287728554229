import { Navigate, useLocation } from "react-router-dom";
import { isLoggedIn } from "./userManager";
import { useAppSelector } from "../store";

export default function AuthComponent({ props, Component }: any) {
    const location = useLocation();
    
    if (location.pathname === '/') return null;
    return isLoggedIn() ? <Component {...props} /> : <Navigate to="/" />
    //return user.isLoggedIn ? <Component {...props} /> : <Navigate to="/" />
}

